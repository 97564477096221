import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAuth } from './state/auth/auth.actions';
import { loadUserStatusSelector } from './state/auth/auth.selectors';
import { AppState } from './state/app-state';
import { LoadingType } from './state/shared/types/loading.type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'boadmin';
  loadUserStatus$ = this.store.select(loadUserStatusSelector);

  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(loadAuth());
  }

  isNotLoading(loadingType: LoadingType | null): boolean {
    return loadingType !== 'pending' && loadingType !== 'loading';
  }
}
